import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { PlantProfileResult } from '../data/fragments/PlantProfileFragment'
import { usePlanteeUser } from '../hooks/usePlanteeUser'
import { Icon } from './Icon'
import styles from './Likes.module.sass'

export type LikesProps = {
	count?: number
	isBackground?: boolean
	isNotButton?: boolean
	isActive?: boolean
	heartColor?: 'default' | 'red'
	plantProfile?: PlantProfileResult
}

export const Likes: FunctionComponent<LikesProps> = ({
	count = 0,
	isBackground,
	isNotButton,
	isActive,
	heartColor,
	plantProfile,
}) => {
	const plantee = usePlanteeUser()
	const likeCount = plantProfile ? plantProfile.paginateLikedByUser.pageInfo.totalCount : 0
	const likedByMe =
		plantProfile && plantee.auth.me.data?.user?.id
			? plantProfile.likedByUser.map((item) => item.id).includes(plantee.auth.me.data.user.id)
			: false

	return (
		<div className={clsx(styles.wrapper, isBackground && styles.is_background)}>
			{isNotButton || !plantProfile ? (
				<div className={styles.notButton}>
					<LikesContent count={count} isActive={isActive} heartColor={heartColor} />
				</div>
			) : (
				<button
					type="button"
					className={styles.button}
					onClick={(e) => {
						e.stopPropagation()
						plantee.auth.likeProfile.mutateAsync({
							profileId: String(plantProfile.id),
							dislike: plantee.auth.likeProfile.data?.likedByMe ?? likedByMe,
						})
					}}>
					<LikesContent
						count={plantee.auth.likeProfile.data?.likeCount ?? likeCount}
						isActive={plantee.auth.likeProfile.data?.likedByMe ?? likedByMe}
						heartColor={heartColor}
					/>
				</button>
			)}
		</div>
	)
}

type LikesContent = {
	count: LikesProps['count']
	isActive?: LikesProps['isActive']
	heartColor?: LikesProps['heartColor']
}

const LikesContent: FunctionComponent<LikesContent> = ({ count, isActive, heartColor }) => {
	return (
		<>
			<span
				className={clsx(
					styles.icon,
					isActive && styles.is_active,
					styles[`is_color_${heartColor}`]
				)}>
				<Icon name="heart" />
				<span className={styles.is_hovered}>
					<Icon name="heartFilled" />
				</span>
			</span>
			<span className={styles.count}>{count}</span>
		</>
	)
}
