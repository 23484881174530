import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import React from 'react'
import type { ImageResult } from '../data/fragments/ImageFragment'
import type { PlantProfileResult } from '../data/fragments/PlantProfileFragment'
import { usePlanteeUser } from '../hooks/usePlanteeUser'
import { Button } from './Buttons'
import styles from './CardPlant.module.sass'
import { CardPlantCarousel } from './CardPlantCarousel'
import { Likes, LikesProps } from './Likes'
import { StatusBadge, StatusBadgeProps } from './StatusBadge'
import { UserBadge, UserBadgeProps } from './UserBadge'

export type CardPlantProps = {
	link?: string
	images: ImageResult[]
	title: string
	status?: StatusBadgeProps['status']
	likes?: LikesProps['count']
	user?: UserBadgeProps
	plantProfile?: PlantProfileResult
	isUserProfilePage?: boolean
}

export const CardPlant: FunctionComponent<CardPlantProps> = ({
	link,
	images,
	title,
	status,
	likes,
	user,
	plantProfile,
	isUserProfilePage,
}) => {
	const [settingsOpen, setSettingsOpen] = React.useState(false)
	const plantee = usePlanteeUser()
	const router = useRouter()

	return (
		<div className={styles.wrapper}>
			{status && (
				<div className={styles.header}>
					<div className={styles.status}>
						<StatusBadge status={status} />
					</div>
					{plantProfile?.author?.id === user?.id && (
						<div className={styles.settings}>
							{/* @TODO: open settings with options (published, rejected, pending, delete) */}
							<button
								type="button"
								className={styles.settingsButton}
								aria-label="card settings"
								onClick={() => {
									setSettingsOpen((old) => !old)
								}}>
								<span className={styles.settingsButtonCircle} />
								<span className={styles.settingsButtonCircle} />
								<span className={styles.settingsButtonCircle} />
							</button>
						</div>
					)}
				</div>
			)}
			{settingsOpen && (
				<ul className={styles.menu}>
					<li>
						<Button
							size={3}
							type="button"
							fontSize="small"
							fill="red600"
							onClick={(e) => {
								e.stopPropagation()
								if (confirm('Do you really want to delete this Plant profile?')) {
									plantee.auth.removeProfile.mutateAsync({
										profileId: String(plantProfile?.id),
									})
									router.reload()
								}
							}}>
							Delete
						</Button>
					</li>
					<li>
						<Button
							size={3}
							type="link"
							fontSize="small"
							fill="groundBlack"
							href={`/create?id=${plantProfile?.id}&update=true`}>
							Edit
						</Button>
					</li>
				</ul>
			)}
			<div className={styles.body}>
				{likes !== undefined && (
					<div className={styles.likes}>
						<Likes
							isActive={isUserProfilePage}
							heartColor={isUserProfilePage ? 'red' : 'default'}
							count={likes}
							isBackground
							plantProfile={plantProfile}
						/>
					</div>
				)}
				{link && (
					<Link href={link}>
						<CardPlantCarousel images={images} />
					</Link>
				)}
				<div className={styles.content}>
					{link && (
						<Link href={link}>
							<h3 className={styles.contentTitle}>{title}</h3>
						</Link>
					)}
					{user && (
						<Link href={`/author/${user.id}`}>
							<div className={styles.contentUser}>
								<UserBadge {...user} />
							</div>
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}
