import clsx from 'clsx'
import Image from 'next/legacy/image'
import { FunctionComponent, UIEvent, useCallback, useEffect, useRef, useState } from 'react'
import type { CardPlantProps } from './CardPlant'
import styles from './CardPlantCarousel.module.sass'

export type CardPlantCarouselProps = {
	images: CardPlantProps['images']
}

export const CardPlantCarousel: FunctionComponent<CardPlantCarouselProps> = ({ images }) => {
	const [isActiveIndex, setActiveIndex] = useState(0)
	const [itemWidth, setItemWidth] = useState(0)
	const itemRef = useRef<HTMLDivElement>(null)
	const itemsRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!itemRef.current || !itemsRef.current) {
			return
		}

		setItemWidth(itemRef.current.getBoundingClientRect().width)
	}, [images.length, itemWidth])

	const srollToItem = useCallback(
		(index: number) => {
			if (!itemsRef.current || !itemRef.current) {
				return
			}

			let leftPos = 0

			leftPos !== itemWidth * index ? (leftPos = itemWidth * index) : leftPos

			itemsRef.current.scrollTo({
				left: leftPos,
				behavior: 'smooth',
			})
			setActiveIndex(index)
		},
		[itemWidth]
	)

	const handleScroll = useCallback(
		(event: UIEvent) => setActiveIndex(Math.floor(event.currentTarget.scrollLeft / itemWidth)),
		[itemWidth]
	)

	return (
		<div className={styles.images}>
			<div className={styles.imagesIn} ref={itemsRef} onScroll={handleScroll}>
				{images.map((item) => (
					<div key={String(item.id)} className={styles.image}>
						<div className={styles.imageIn} ref={itemRef}>
							<Image
								src={item.url}
								layout="fill"
								objectFit="cover"
								alt={item.alt ?? item.description ?? ''}
								placeholder="blur"
								blurDataURL={item.url}
							/>
						</div>
					</div>
				))}
			</div>
			{images.length > 1 && (
				<div className={styles.pagination}>
					{images.map((item, index) => (
						<button
							key={String(item.id)}
							className={clsx(styles.paginationBullet, index === isActiveIndex && styles.is_active)}
							aria-label="Bullet"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								srollToItem(index)
							}}
						/>
					))}
				</div>
			)}
		</div>
	)
}
