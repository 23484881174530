import Link from 'next/link'
import type { FunctionComponent, ReactNode } from 'react'
import { LinkType } from '../../generated/content'
import type { InternalLinkResult } from '../data/fragments/InternalLinkFragment'
import type { LinkResult } from '../data/fragments/LinkFragment'
import { assertDefined } from '../utils/assertDefined'
import { assertNever } from '../utils/assertNever'

export interface ContemberLinkProps {
	link: LinkResult
	children: ReactNode
	className?: string
}

export const ContemberLink: FunctionComponent<ContemberLinkProps> = ({
	link: { type, externalLink, internalLink },
	children,
	className,
}) => {
	if (type === LinkType.internal) {
		return (
			<ContemberInternalLink link={internalLink} className={className}>
				{children}
			</ContemberInternalLink>
		)
	} else if (type === LinkType.external) {
		return (
			<Link href={externalLink ?? '#'} className={className}>
				{children}
			</Link>
		)
	} else {
		return assertNever(type)
	}
}

export interface ContemberInternalLinkProps {
	link: InternalLinkResult | undefined
	children: ReactNode
	className?: string
}

export const ContemberInternalLink: FunctionComponent<ContemberInternalLinkProps> = ({
	link,
	children,
	className,
}) => {
	assertDefined(link)
	return (
		<Link href={link.url} className={className}>
			{children}
		</Link>
	)
}
