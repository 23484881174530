import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Icon } from './Icon'
import styles from './StatusBadge.module.sass'

export type StatusBadgeProps = {
	status: 'rejected' | 'pending' | 'published'
	label?: string
}

export const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ status, label }) => {
	const icon = status === 'rejected' ? 'reject' : status === 'published' ? 'success' : status
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.main, styles[`is_${status}`])}>
				<span className={styles.icon}>
					<Icon name={icon} />
				</span>
				<span className={styles.label}>
					{label ?? status[0].toUpperCase() + status.substring(1)}
				</span>
			</div>
		</div>
	)
}
