import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent, ReactNode } from 'react'
import type { ImageResult } from '../data/fragments/ImageFragment'
import type { LinkResult } from '../data/fragments/LinkFragment'
import { ContemberLink } from './ContemberLink'
import styles from './UserBadge.module.sass'

export type UserBadgeProps = {
	link?: LinkResult
	image?: ImageResult
	name: string
	size?: 'small' | 'medium' | 'big'
	isHighlighted?: boolean
	id?: string
}

export const UserBadge: FunctionComponent<UserBadgeProps> = ({
	link,
	image,
	name,
	size = 'small',
	isHighlighted,
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				size && styles[`is_size_${size}`],
				isHighlighted && styles.is_highlighted
			)}>
			<span className={styles.label}>By</span>
			<UserBadgeLink link={link}>
				<div className={styles.main}>
					<div className={styles.image}>
						<div className={styles.imageIn}>
							{image && (
								<Image
									src={image.url}
									layout="fill"
									objectFit="cover"
									alt={image.alt ?? image.description ?? ''}
									sizes="30px"
									placeholder="blur"
									blurDataURL={image.url}
								/>
							)}
						</div>
					</div>
					<span className={styles.name}>{name}</span>
				</div>
			</UserBadgeLink>
		</div>
	)
}

type UserBadgeLink = {
	link?: LinkResult
	children: ReactNode
}

const UserBadgeLink: FunctionComponent<UserBadgeLink> = ({ link, children }) => {
	if (link) {
		return (
			<ContemberLink link={link} className={styles.link}>
				{children}
			</ContemberLink>
		)
	} else {
		return <>{children}</>
	}
}
